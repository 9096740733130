<template>
    <el-container>
        <el-main>
            <div style="display: flex">
                <el-select v-model="query.status" placeholder="状态" clearable style="width: 100px">
                    <el-option v-for="(v, k) in image_status_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
                <el-select v-model="query.category_id" placeholder="分类" clearable style="width: 100px">
                    <el-option v-for="c in category_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>
                </el-select>
                <el-select v-model="query.module" placeholder="模块" clearable style="width: 100px">
                    <el-option v-for="(v, k) in image_module_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
                <el-select v-model="query.type" placeholder="类型" clearable style="width: 100px">
                    <el-option v-for="(v, k) in image_type_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
                <el-select v-model="query.difficulty" placeholder="难度" clearable style="width: 100px">
                    <el-option v-for="(v, k) in image_difficulty_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
                <el-select v-model="query.skill" placeholder="画法" clearable style="width: 100px">
                    <el-option v-for="v in image_skill_enum" :label="v" :value="v"></el-option>
                </el-select>
                <el-select v-model="query.author" placeholder="作者" clearable style="width: 100px">
                    <el-option v-for="a in author_list" :value="a"></el-option>
                </el-select>
                <el-date-picker v-model="query.finish_date" value-format="YYYY-MM-DD" type="daterange" clearable
                                start-placeholder="完成开始日期" end-placeholder="完成结束日期"></el-date-picker>
                <el-input v-model="query.id" style="width: 100px" placeholder="图片ID"></el-input>
                <el-input v-model="query.key" style="width: 100px" placeholder="图片KEY"></el-input>
                <el-button type="primary" :loading="loading" @click="openDialog()">创建</el-button>
            </div>
            <el-table style="margin-top: 20px;" v-loading="loading" :data="dataPage">
                <el-table-column label="预览图" width="230">
                    <template #default="scope">
                        <el-image lazy :src="`https://color-cdn.rabigame.com/${scope.row.thumbnail}`"
                                  style="width: 100px" v-if="scope.row.thumbnail"></el-image>
                        <img src="./assets/magic.webp" v-else style="width: 100px">
                        <el-image lazy :src="`https://color-cdn.rabigame.com/material/${scope.row.key}/finished.webp`"
                                  style="width: 100px"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="id" prop="id" width="60"></el-table-column>
                <el-table-column label="key" prop="key" width="60"></el-table-column>
                <el-table-column label="状态" prop="status" width="110">
                    <template #default="scope">
                <span style="color: var(--el-color-danger)" v-if="[4].includes(scope.row.status)">
                    {{ image_status_enum[scope.row.status] }}</span>
                        <span style="color: var(--el-color-success)"
                              v-else-if="scope.row.status === 3 && scope.row.online_date <= timestampToDate(Date.now())">
                    已上线</span>
                        <el-select v-model="scope.row.status" :disabled="loading"
                                   v-else-if="[1, 2, 3].includes(scope.row.status)" disabled>
                            <el-option :value="1" label="测试中"></el-option>
                            <el-option :value="2" label="不通过"></el-option>
                            <el-option :value="3" label="通过"></el-option>
                        </el-select>
                        <span style="color: var(--el-color-info)" v-else>
                    {{ image_status_enum[scope.row.status] || '正在排队' }}</span>
                        <span style="color: var(--el-color-warning)"
                              v-if="[1].includes(scope.row.status) && !scope.row.origin_mask1">
                    等待排序</span>
                        <span style="color: var(--el-color-success)" v-if="scope.row.good">建议通过</span>
                        <span style="color: var(--el-color-error)" v-else-if="scope.row.good===false">建议不通过</span>
                        <el-progress v-if="scope.row.status === 0" :text-inside="true" :stroke-width="16"
                                     :percentage="parseInt(scope.row.progress * 100 / scope.row.cells)">
                        </el-progress>
                    </template>
                </el-table-column>
                <el-table-column label="类型" prop="type" width="60">
                    <template #default="scope">
                        {{ image_type_enum[scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column label="色块" prop="cells" width="60"></el-table-column>
                <el-table-column label="作者" prop="authors" width="80">
                    <template #default="scope">
                        {{ scope.row.authors.join(',') }}
                    </template>
                </el-table-column>
                <el-table-column label="难度" prop="difficulty" width="60">
                    <template #default="scope">
                        <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                            L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="修改" width="60" prop="modify"></el-table-column>
                <el-table-column label="完成时间" prop="finish_date" width="120"></el-table-column>
                <el-table-column label="上传时间" prop="c_time" width="120">
                    <template #default="scope">
                        {{ timestampToDate(scope.row.c_time) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                        <el-button size="small" :disabled="loading" @click="$refs.test.init(scope.row)" type="success"
                                   v-if="[1,2,3].includes(scope.row.status)">测试
                        </el-button>
                        <!--                        <el-button size="small" :disabled="loading" @click="$refs.mask.init(scope.row)"-->
                        <!--                                   v-if="[1,2,3].includes(scope.row.status)">顺序-->
                        <!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 10px;text-align: center;">
                <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                               :total="dataFilter.length"></el-pagination>
            </div>
            <el-dialog v-model="dialog_opened" :before-close="reset" width="80%" :close-on-click-modal="false">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请选择分类'}]">
                        <el-radio-group v-model="form.type" :disabled="loading">
                            <el-radio v-for="(v, k) in image_type_enum" :label="parseInt(k)">{{ v }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="激励视频" prop="video" :rules="[{required: true, message: '请选择是否激励视频解锁'}]">
                        <el-radio-group v-model="form.video" :disabled="loading">
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="完成日期" prop="finish_date" :rules="[{required: true , message: '请选择完成日期'}]">
                        <el-date-picker v-model="form.finish_date" value-format="YYYY-MM-DD"
                                        :disabled="loading"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="静态完成图" prop="origin_finish" :rules="[{required: true, message: '请上传完成图'}]">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleFinish"
                                   accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                                   :disabled="editing && ![2, 4].includes(editing.status)">
                            <img v-if="form.origin_finish" :src="`https://color-cdn.rabigame.com/${form.origin_finish}`"
                                 style="width: 200px">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="动态完成图" prop="origin_gif" :rules="[{required: true, message: '请上传动态完成图'}]"
                                  v-if="form.type === 30">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleGif"
                                   accept="image/gif" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                                   :disabled="editing && ![2, 4].includes(editing.status)">
                            <img v-if="form.origin_gif" :src="`https://color-cdn.rabigame.com/${form.origin_gif}`"
                                 style="width: 200px">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="前景图" prop="origin_svg" :rules="[{required: true, message: '请上传前景图'}]"
                                  v-if="form.type !== 20">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSvg"
                                   accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                                   :disabled="editing && ![2, 4].includes(editing.status)" :before-upload="checkMask">
                            <img v-if="form.origin_svg" :src="`https://color-cdn.rabigame.com/${form.origin_svg}`"
                                 style="width: 200px">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="Mask" prop="origin_mask" v-if="![5, 20].includes(form.type)">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleMask"
                                   accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                                   :disabled="editing && ![2, 4].includes(editing.status)" :before-upload="checkMask">
                            <img v-if="form.origin_mask" :src="`https://color-cdn.rabigame.com/${form.origin_mask}`"
                                 style="width: 200px">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="缩略图" prop="origin_thumb" :rules="[{required: true, message: '请上传缩略图'}]"
                                  v-if="form.type !== 20">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumb"
                                   accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress">
                            <img :src="`https://color-cdn.rabigame.com/${form.origin_thumb}`"
                                 style="width: 200px" v-if="form.origin_thumb">
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="正方形缩略图" prop="origin_thumb1">
                        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumb1"
                                   accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress">
                            <div v-if="form.origin_thumb1" style="position: relative">
                                <img :src="`https://color-cdn.rabigame.com/${form.origin_thumb1}`" style="width: 200px">
                                <el-icon style="position: absolute;top: 0;right: 0;color: var(--el-color-error)"
                                         @click.stop="form.origin_thumb1=null">
                                    <CircleClose/>
                                </el-icon>
                            </div>
                            <el-button size="small" v-else :loading="loading">上传</el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
                    <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
                </template>
            </el-dialog>
        </el-main>
    </el-container>
    <Test ref="test"></Test>
    <Mask ref="mask"></Mask>
    <mask-error ref="maskError"></mask-error>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, update} from "./libs/utils";
import Test from "./Test";
import Mask from './Mask';
import {CircleClose} from '@element-plus/icons-vue'
import MaskError from './MaskError';

export default {
    name: 'Index',
    components: {Test, Mask, CircleClose, MaskError},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], category_list: [], page: 1, size: 50, author_list: [], dialog_opened: false,
            editing: null, content_tag: [], style_tag: [], total: 0, major: null, minor: null, style: null,
            image_status_enum: {0: '处理中', 1: '测试中', 2: '未通过', 3: '已通过', 4: '处理失败', 5: '已下线', 9: '等待排序', 10: '已上线'},
            image_module_enum: {0: '图库', 1: '发现页', 2: 'Bonus', 3: 'IP', 4: 'DAILY', 5: '收集活动'},
            image_skill_enum: ['描线', '单色', '平涂', '复杂平涂', '厚涂', '复杂厚涂', '中等平涂', '只上色'],
            image_difficulty_enum: {1: 'L1', 2: 'L2', 3: 'L3', 4: 'L4'},
            image_tag_enum: {
                1: 'Special',
                2: 'Wallpaper',
                3: 'Collection',
                4: 'Artist',
                5: 'Music',
                6: 'Magic',
                7: 'Hot',
                21: '感恩节',
                // 22: '万圣节',
                23: '圣诞节',
                24: '新年',
                25: '情人节',
                26: '复活节',
                27: '母亲节',
                28: '独立日',
                29: '万圣节',
            },
            image_type_enum: {6: '普通非单色'}, timeout_list: [],
            query: {
                status: null,
                id: null,
                key: null,
                module: null,
                category_id: null,
                finish_date: null,
                author: null,
                skill: null,
                type: null,
                difficulty: null,
            },
            form: {
                authors: [],
                categories: [],
                difficulty: 1,
                major_tags: [],
                minor_tags: [],
                style_tags: ['描线', '厚涂写实'],
                module: 0,
                tags: [],
                type: 6,
                video: false,
                finish_date: timestampToDate(now),
                online_date: null,
                origin_thumb: null,
                origin_thumb1: null,
                origin_finish: null,
                origin_mask: null,
                origin_svg: null,
                origin_gif: null,
                status: null,
                skill: null,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/part-time/image`).then(res => {
                    this.data = res.data.data.imageList;
                    this.total = this.data.length;
                    this.author_list = [];
                    this.data.forEach(d => {
                        d.authors.forEach(a => {
                            if (!this.author_list.includes(a)) {
                                this.author_list.push(a);
                            }
                        })
                        this.refreshOne(d);
                    })
                }),
                // axios.get('/cms/v1/category').then(res => {
                //     this.category_list = res.data.data.categoryList;
                // }),
                // axios.get(`/cms/v1/tag/content`).then(res => {
                //     this.content_tag = res.data.data.tagList;
                // }),
                // axios.get(`/cms/v1/tag/style`).then(res => {
                //     this.style_tag = res.data.data.tagList;
                // }),
                axios.get(`/cms/v1/history/image`).then(res => {
                    this.history = res.data.data.countList;
                }),
            ]).then(_ => {
                this.data.forEach(d => {
                    this.history.forEach(h => {
                        if (d.id === h.image_id) {
                            d.modify = h.count;
                        }
                    })
                })
                this.loading = false;
            })
        },
        refreshOne(row) {
            if (row.status === null) {
                this.timeout_list.push(setTimeout(_ => {
                    axios.get(`/cms/v1/image/${row._id}`).then(res => {
                        update(row, res.data.data);
                        this.refreshOne(row);
                    });
                }, 10 + Math.random() * 10000));
            } else if (row.status === 0) {
                this.timeout_list.push(setTimeout(_ => {
                    axios.get(`/cms/v1/image/${row._id}`).then(res => {
                        update(row, res.data.data);
                        this.refreshOne(row);
                    });
                }, 3000));
            }
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.categories = [];
            this.form.major_tags = [];
            this.form.minor_tags = [];
            this.form.style_tags = ['描线', '厚涂写实'];
            this.form.authors = [];
            this.form.tags = [];
            this.editing = null;
            this.major = this.minor = this.style = null;
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/part-time/image/${this.editing._id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.refreshOne(this.editing);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/part-time/image`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.refreshOne(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        removeTag(category, tag) {
            this.form[category].splice(this.form[category].indexOf(tag), 1);
        },
        addTag(value, category) {
            this.form[category].push(value[value.length - 1]);
        },
        handleFinish(res) {
            this.loading = false;
            this.form.origin_finish = res.data.name
        },
        handleGif(res) {
            this.loading = false;
            this.form.origin_gif = res.data.name
        },
        handleSvg(res) {
            this.loading = false;
            this.form.origin_svg = res.data.name
        },
        handleThumb(res) {
            this.loading = false;
            this.form.origin_thumb = res.data.name
        },
        handleThumb1(res) {
            this.loading = false;
            this.form.origin_thumb1 = res.data.name
        },
        handleMask(res) {
            this.loading = false;
            this.form.origin_mask = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        restart(row) {
            this.loading = true;
            axios.post(`/cms/v1/image/${row._id}/reprocess`).then(res => {
                row.status = null;
                this.refreshOne(row);
                this.loading = false;
            });
        },
        checkMask(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event => {
                    const image = new Image();
                    image.onload = _ => {
                        const canvas = document.createElement('canvas');
                        canvas.width = image.width;
                        canvas.height = image.height;
                        const context = canvas.getContext('2d');
                        context.drawImage(image, 0, 0);
                        const data = context.getImageData(0, 0, image.width, image.height);
                        for (let i = 0; i < data.data.length; i += 4) {
                            if (data.data[i] === 0 && data.data[i + 1] === 0 && data.data[i + 2] === 0 || data.data[i] === 255 && data.data[i + 1] === 255 && data.data[i + 2] === 255 || data.data[i + 3] === 0) {
                            } else {
                                reject();
                                this.form.origin_mask = null;
                                this.$refs.maskError.open(image);
                                return
                            }
                        }
                        resolve(true);
                    }
                    image.src = event.target.result;
                };
                reader.readAsDataURL(file);
            })
        },
    },
    mounted() {
        this.init();
    },
    beforeUnmount() {
        this.timeout_list.forEach(t => {
            clearTimeout(t);
        });
    },
    computed: {
        dataFilter() {
            const now = timestampToDate(Date.now());
            return this.data.filter(d => {
                return (!this.query.status && this.query.status !== 0 || (this.query.status === 9 && d.status === 1 && !d.origin_mask1) || (d.status === this.query.status && (!d.online_date || d.online_date > now) || this.query.status === 10 && d.online_date && d.online_date <= now))
                    && (!this.query.id || (d.id + '').includes(this.query.id))
                    && (!this.query.key || d.key.includes(this.query.key))
                    && (!this.query.author || d.authors.includes(this.query.author))
                    && (!this.query.skill || d.skill === this.query.skill)
                    && (!this.query.type || d.type === this.query.type)
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (!this.query.module && this.query.module !== 0 || d.module === this.query.module)
                    && (!this.query.finish_date || d.finish_date >= this.query.finish_date[0] & d.finish_date <= this.query.finish_date[1])
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (!this.query.difficulty || d.difficulty === this.query.difficulty || d.cells > 800 && this.query.difficulty === 4 || d.cells > 500 && d.cells <= 800 && this.query.difficulty === 3 || d.cells > 300 && d.cells <= 500 && this.query.difficulty === 2 || d.cells <= 300 && this.query.difficulty === 1)
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        }
    }
}
</script>

<style scoped>

</style>