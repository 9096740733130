<template>
    <el-dialog v-model="dialog_opened">
        <p>非二值化图片</p>
        <canvas id="canvas" :style="{height: $root.height - 100 + 'px'}"></canvas>
    </el-dialog>
</template>

<script>

export default {
    name: "MaskError",
    data() {
        return {
            dialog_opened: false,
        }
    },
    methods: {
        open(image) {
            this.dialog_opened = true;
            this.$nextTick(() => {
                this.draw(image);
            });
        },
        draw(image) {
            const canvas = document.getElementById("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            const data = ctx.getImageData(0, 0, image.width, image.height);
            for (let i = 0; i < data.data.length; i += 4) {
                if (data.data[i] === 0 && data.data[i + 1] === 0 && data.data[i + 2] === 0 || data.data[i] === 255 && data.data[i + 1] === 255 && data.data[i + 2] === 255 || data.data[i + 3] === 0) {
                } else {
                    data.data[i] = data.data[i + 3] = 255;
                    data.data[i + 1] = data.data[i + 2] = 0;
                }
            }
            ctx.putImageData(data, 0, 0);
        },
    },
}
</script>

<style scoped>

</style>