<template>
    <div class="login">
        <el-card header="登录页面">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="用户名" prop="username" :rules="[{required: true, message: '请输入用户名'}]">
                    <el-input v-model="form.username" :disabled="loading"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" :rules="[{required: true, message: '请输入密码'}]">
                    <el-input v-model="form.password" :disabled="loading" show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import {ElMessage} from 'element-plus'

export default {
    name: "Login",
    data() {
        return {
            loading: false,
            form: {
                username: '',
                password: '',
            }
        }
    },
    methods: {
        login() {
            this.loading = true;
            axios.post(`/cms/v1/part-time/login`, this.form).then(_ => {
                top.location.reload();
                this.loading = false;
            }).catch(_ => {
                ElMessage.error('用户名密码错误！')
                this.loading = false;
            })
        },
    },
}
</script>

<style scoped>
.login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--el-bg-color-page);
}
</style>