import {createApp, Component} from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import axios from 'ts-axios-new'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {update} from './libs/utils'


const root_props = {
    // user info
    user: {
        id: '',
        name: '',
    },
    country_list: [],
};

function init() {
    const app = createApp(App);
    app.use(ElementPlus, {locale: zhCn});
    const vm = app.mount('#app');
    update(vm, root_props);
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, <Component>component)
    }
}

function login() {
    const app = createApp(Login);
    app.use(ElementPlus, {locale: zhCn});
    app.mount('#login');
    document.getElementById('login').style.height = '100%'
}

window.onload = function () {
    axios.get(`/cms/v1/me`).then(_ => {
        init();
    }, _ => {
        login();
    })
}